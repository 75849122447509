import axios, { AxiosRequestConfig } from "axios";

export class Endpoint {
  constructor(private authToken: string) {}

  public get(endpoint: string, params?: AxiosRequestConfig) {
    return axios({
      method: "get",
      url: `${endpoint}`,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        ...params?.headers,
      },
      ...params,
    });
  }

  public post(endpoint: string, params: AxiosRequestConfig) {
    return axios({
      method: "post",
      url: `${endpoint}`,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        ...params?.headers,
      },
      ...params,
    });
  }

  public put(endpoint: string, params: AxiosRequestConfig) {
    return axios({
      method: "put",
      url: `${endpoint}`,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        ...params?.headers,
      },
      ...params,
    });
  }

  public patch(endpoint: string, params: AxiosRequestConfig) {
    return axios({
      method: "patch",
      url: `${endpoint}`,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        ...params?.headers,
      },
      ...params,
    });
  }

  public delete(endpoint: string, params: AxiosRequestConfig) {
    return axios({
      method: "delete",
      url: `${endpoint}`,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        ...params?.headers,
      },
      ...params,
    });
  }

}

export default Endpoint;

// import axios, { AxiosRequestConfig, Method } from "axios";

// export class Endpoint {
//   constructor(private authToken: string) {}

//   private request(method: Method, endpoint: string, params?: AxiosRequestConfig) {
//     return axios({
//       method,
//       url: `${endpoint}`,
//       withCredentials: true,
//       headers: {
//         Authorization: `Bearer ${this.authToken}`,
//         ...params?.headers,
//       },
//       ...params,
//     });
//   }

//   public get(endpoint: string, params?: AxiosRequestConfig) {
//     return this.request('get', endpoint, params);
//   }

//   public post(endpoint: string, params: AxiosRequestConfig) {
//     return this.request('post', endpoint, params);
//   }

//   public put(endpoint: string, params: AxiosRequestConfig) {
//     return this.request('put', endpoint, params);
//   }

//   public patch(endpoint: string, params: AxiosRequestConfig) {
//     return this.request('patch', endpoint, params);
//   }

//   public delete(endpoint: string, params: AxiosRequestConfig) {
//     return this.request('delete', endpoint, params);
//   }
// }

// export default Endpoint;
