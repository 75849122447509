// followed https://sdk.vercel.ai/docs/ai-sdk-ui/chatbot and stopped before the "modify messages" section

import React from 'react';
import { Message, useChat } from 'ai/react';
import { Box, Button, Typography, TextField, CircularProgress } from '@mui/material';
import { useAccessToken } from '../../hooks/access-token.hook';

function ChatPage() {
  const accessToken = useAccessToken();

  const { messages, input, handleInputChange, handleSubmit, isLoading, stop, error, reload } =
    useChat({
      keepLastMessageOnError: true,
      api: 'https://api.allmendelabs.com/user/chat_stream',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
      streamProtocol: 'text',
      // streamProtocol: 'data',
      // will probably need to update streamProtocol to 'data' to support tool calls https://sdk.vercel.ai/docs/troubleshooting/common-issues/use-chat-failed-to-parse-stream
      // sounds like useChat is being deprecated anyways so should probably look at migrating when implementing toolcalls anyways
      // reference for now: https://sdk.vercel.ai/docs/reference/ai-sdk-ui/use-chat
    });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleSubmit();
      }
    };

  return (
    <Box sx={{ p: 2 }}>
      {messages.map((message: Message) => (
        <Box key={message.id} sx={{ mb: 2 }}>
          {message.role === 'user' ? rightChatBubble({ message: message.content }) : leftChatBubble({ message: message.content })}
        </Box>
      ))}

      {isLoading && (
        <div>
          <CircularProgress />
          <button type="button" onClick={() => stop()}>
            Stop
          </button>
        </div>
      )}

      {error && (
        console.log(error),
        <>
          <div>An error occurred.</div>
          <button type="button" onClick={() => reload()}>
            Retry
          </button>
        </>
      )}

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          name="prompt"
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          variant="outlined"
          placeholder="Type your message..."
          multiline
          InputProps={{ sx: { borderRadius: 2 } }}
          inputProps={{ style: {
            fontSize: 18,
            // color: 'white'
          }}}
          sx={{ mb: 2 }}
          disabled={isLoading}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Box>
  );
};

const leftChatBubble = ({ message }: { message: string }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'left' }}>
      <Box
        sx={{
          display: 'flex',
          float: 'left',
          backgroundColor: '#333333',
          borderRadius: '1rem',
        }}
      >
        <Typography variant="body1" sx={{ p: '0.25rem 0.75rem', color: 'white' }}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

const rightChatBubble = ({ message }: { message: string }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
      <Box
        sx={{
          display: 'flex',
          float: 'right',
          backgroundColor: '#418ac4',
          borderRadius: '1rem',
        }}
      >
        <Typography variant="body1" sx={{ p: '0.25rem 0.75rem', color: 'white' }}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

export default ChatPage;
