import { useState, useEffect } from "react";
// import Endpoint from "../../utils/endpoint.hook.util";
import useEndpoint from "../../hooks/endpoint.hook";
import * as auth0React from "@auth0/auth0-react";
import AlertMessageComponent from "../../components/Alert";
import MetadataEditor from "../../components/MetadataEditor";
import {
  Box,
  Typography,
} from "@mui/material";

const Profile = () => {
  const { user } = auth0React.useAuth0();
  const endpoint = useEndpoint();

  const [stripeuserData, setStripeuserData] = useState(null);
  const [stripeError, setStripeError] = useState(null);

  useEffect(() => {
    if (user) {
      endpoint?.get(`https://api.allmendelabs.com/user/stripe/profile`)
        .then((res) => {
          console.log(res.data);
          setStripeuserData(res.data);
        })
        .catch((err) => {
          console.log(err);
          setStripeError(err.message);
        });
    }
  }, [endpoint, user]);

  if (!user) {
    return (
      <Box>
        <Typography variant="h2" sx={{ margin:'1em'}}>Error! No User</Typography>
      </Box>
    )
  }
  
  return (
    <Box>
      <Typography variant="h4" sx={{ margin:'1em'}}>Auth0 User Profile</Typography>
      <AlertMessageComponent message={user} isErrorMessage={false} />
      <Typography variant="h4" sx={{ margin:'1em'}}>Auth0 Metadata:</Typography>
      <MetadataEditor/>
      <Typography variant="h4" sx={{ margin:'1em'}}>Stripe User Profile</Typography>
      {stripeuserData ? (<AlertMessageComponent message={stripeuserData} isErrorMessage={false} />) : stripeError && (<AlertMessageComponent message={stripeError} isErrorMessage={true} />) }
    </Box>
  )
}

export default Profile