import { useState, useEffect } from "react";
import useEndpoint from '../../hooks/endpoint.hook';
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Button,
} from '@mui/material';

export default function App() {
  const endpoint = useEndpoint();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    endpoint?.get(
      `https://api.allmendelabs.com/user/s3/list_files`,
      {}
    ).then((response) => {
      console.log(`response: ${JSON.stringify(response)}`);
      setFiles(response.data.files);
    }).catch((err) => {
        console.error(err.message);
    });
  });

  return (
    <div className="App">
      <br />
      {files !== undefined && files.length > 0 ? (
        <List>
          <ListItem>
            <ListItemText primary="File Name" primaryTypographyProps={{ fontWeight: 'bold' }}/>
            <ListItemText primary="Created At" primaryTypographyProps={{ fontWeight: 'bold' }}/>
            <ListItemText primary="" primaryTypographyProps={{ fontWeight: 'bold' }}/>
          </ListItem>
          {files.map((file: any) => {
            const fileName = `/${file.split('_')[1]}`;
            const unixTimestamp = file.split('_')[0].slice(1);
            const date = new Date(unixTimestamp * 1000);
            const createdAt = date.toLocaleString();

            return (
              <ListItem key={file}>
                <ListItemText primary={fileName} />
                <ListItemText primary={createdAt} />
                <Button>Chat</Button>
              </ListItem>
            );
          })}
        </List>
      ):(
        <CircularProgress sx={{ margin: "1rem"}}/>
      )}
    </div>
  );
}
