import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Link, MenuItem, Menu, Tooltip, IconButton, Avatar } from '@mui/material';
import { useAuth } from '../components/Auth';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';


const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Tooltip title="Login">
      <IconButton onClick={() => loginWithRedirect()}>
        <Avatar src="/broken-image.jpg" />
      </IconButton>
    </Tooltip>
  );
};
export interface DropdownSelectionItem {
  title: string;
  onClick?: () => void | Promise<void>;
}

export default function Navbar() {
  const { isLoggedIn } = useAuth()!;
  const { logout } = useAuth0();

  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate('/user/profile');
    handleCloseUserMenu();
  };

  const handleLogoutClick = () => {
    console.log('logout called')
    localStorage.removeItem('NRBA_SESSION')
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    })
    handleCloseUserMenu();
  }

  const handleFileUploadClick = () => {
    navigate('/user/fileupload');
    handleCloseUserMenu();
  }

  const handleViewFilesClick = () => {
    navigate('/user/viewfiles');
    handleCloseUserMenu();
  }

  const handleChatClick = () => {
    navigate('/user/chat');
    handleCloseUserMenu();
  }

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="sticky" sx={{ top: 0, bgcolor: 'grey.900', zIndex: 10 }}>
      <Toolbar sx={{ height: 56, px: { xs: 2, lg: 3 }, display: 'flex', justifyContent: 'space-between' }}>
        <Link href="/" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box component="img" src="/icon.png" alt="allmende AI" width={50} height={50} />
          <Typography sx={{ position: 'absolute', width: 1, height: 1, overflow: 'hidden', clip: 'rect(0 0 0 0)', whiteSpace: 'nowrap' }}>
            Allmende AI
          </Typography>
        </Link>
        <Box component="nav" sx={{ display: 'flex', gap: { xs: 2, sm: 8 }, mx: 'auto' }}>
          {['ABOUT', 'TESTIMONIALS', 'CONTACT US'].map((item, index) => (
            <Link
              key={item}
              href={index === 2 ? '/contact' : `/#${item.toLowerCase()}`}
              sx={{
                fontSize: '0.875rem',
                fontWeight: 'bold',
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  textUnderlineOffset: '4px',
                },
              }}
            >
              {item}
            </Link>
          ))}
        </Box>
        
        {isLoggedIn ?(
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar src="/broken-image.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleChatClick}>
                <Typography textAlign="center">Chat</Typography>
              </MenuItem>
              <MenuItem onClick={handleFileUploadClick}>
                <Typography textAlign="center">File Upload</Typography>
              </MenuItem>
              <MenuItem onClick={handleViewFilesClick}>
                <Typography textAlign="center">View Files</Typography>
              </MenuItem>
              <MenuItem onClick={handleProfileClick}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              {/* maybe red on hover instead */}
              <MenuItem onClick={handleLogoutClick} sx={{ color: 'red' }}>
                <Typography textAlign="center">Log Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
          ):(<LoginButton />)
        }
      </Toolbar>
    </AppBar>
  );
}
