import { FileUpload, FileUploadProps } from "../../components/FileUpload";

const fileUploadProp: FileUploadProps = {
  // accept: ".txt",
  accept: ".txt,.md,.pdf,.doc,.docx",
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
          event.target.files !== null &&
          event.target?.files?.length > 0
      ) {
          console.log(`Saving ${event.target.value}`)
      }
  },
  onDrop: (event: React.DragEvent<HTMLElement>) => {
      console.log(`Drop ${event.dataTransfer.files[0].name}`)
  },
}

function FileUploadPage() {
  return (
    <div>
      <FileUpload {...fileUploadProp} />
    </div>
  );
}

export default FileUploadPage