import React, { useState } from 'react';
import axios from 'axios';
import useEndpoint from '../hooks/endpoint.hook';
import { Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export type FileUploadProps = {
    accept: string;
    hoverLabel?: string;
    dropLabel?: string;
    width?: string;
    height?: string;
    backgroundColor?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onDrop?: (event: React.DragEvent<HTMLElement>) => void;
};

export const FileUpload: React.FC<FileUploadProps> = ({
    accept,
    hoverLabel = 'Click or drag to upload a file',
    dropLabel = 'Drop file here',
    width = '250px',
    height = '250px',
    backgroundColor = '#fff',
    onChange,
    onDrop,
}) => {

    const endpoint = useEndpoint();

    const [labelText, setLabelText] = useState<string>(hoverLabel);
    const [isDragOver, setIsDragOver] = useState<boolean>(false);
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);

    const stopDefaults = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const dragEvents = {
        onMouseEnter: () => {
            setIsMouseOver(true);
        },
        onMouseLeave: () => {
            setIsMouseOver(false);
        },
        onDragEnter: (e: React.DragEvent) => {
            stopDefaults(e);
            setIsDragOver(true);
            setLabelText(dropLabel);
        },
        onDragLeave: (e: React.DragEvent) => {
            stopDefaults(e);
            setIsDragOver(false);
            setLabelText(hoverLabel);
        },
        onDragOver: stopDefaults,
        onDrop: (e: React.DragEvent<HTMLElement>) => {
            stopDefaults(e);
            setLabelText(hoverLabel);
            setIsDragOver(false);
            handleFileUpload(e.dataTransfer.files[0]);
            if (onDrop) onDrop(e);
        },
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            handleFileUpload(event.target.files[0]);
        }
        if (onChange) onChange(event);
    };

    const handleFileUpload = async (file: File) => {
        try {
            setUploading(true);

            console.log(`Getting signed s3 url for ${file.name}`);
    
            const response = await endpoint?.get(
                'https://api.allmendelabs.com/user/s3/upload_url',
                { params: {
                    filename: file.name,
                } }
            );

            if (!response) { throw new Error('No response from API'); };
            if (!response.data) { throw new Error('No data in response from API'); };

            console.log(`Got signed s3 url response: ${JSON.stringify(response.data)}`);

            const { s3Key, url } = response.data;
            console.log(`Received upload URL: ${url}`);
    
            // Upload the file to S3 using the pre-signed URL
            const uploadResponse = await axios.put(url, file, {
                headers: {
                    'Content-Type': file.type,
                    'x-amz-server-side-encryption': 'AES256', // If your bucket requires encryption
                },
                transformRequest: [(data) => data],
                // Important: Prevent axios from modifying our headers
                maxBodyLength: Infinity,
                maxContentLength: Infinity,
            });
            if (uploadResponse.status === 200) {
                console.log('File uploaded successfully');
                console.log('Uploaded file key:', s3Key);
                alert('File uploaded successfully!');
            } else {
                throw new Error(`Upload failed with status ${uploadResponse.status}`);
            }

        } catch (error) {
            console.error('Error uploading file:', error);
            alert('File upload failed.');
        } finally {
            setUploading(false);
        }
    };

    return (
        <Box style={{ margin:'1em 2em' }}>
            <input
                onChange={handleChange}
                accept={accept}
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
            />

            <label
                htmlFor="file-upload"
                {...dragEvents}
                style={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    width: width,
                    height: height,
                    backgroundColor: backgroundColor,
                    borderRadius: '10px',
                    border: '2px black',
                    opacity: uploading ? 0.5 : 1, // Add opacity when uploading
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        opacity: isDragOver || isMouseOver ? 1 : 0.4,
                        transition: 'opacity 0.3s',
                        color: 'black', // Set the text color to black
                    }}
                >
                    <CloudUploadIcon fontSize="large" style={{ color: 'black' }} />
                    <Typography style={{ color: 'black' }}>{uploading ? 'Uploading...' : labelText}</Typography> 
                </Box>
            </label>
        </Box>
    );
};
