import React, { useState, useEffect } from 'react';
import useEndpoint from '../hooks/endpoint.hook';
import AlertMessageComponent from './Alert';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Backdrop,
  CircularProgress,
  Divider,
} from '@mui/material';

type UserMetadata = {
  [key: string]: string;
};

const MetadataEditor = (): JSX.Element => {


  const endpoint = useEndpoint();

  const [userMetadata, setUserMetadata] = useState<UserMetadata>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // state for update modal
  const [editableKey, setEditableKey] = useState<string | null>(null);
  const [editableValue, setEditableValue] = useState<string>('');
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);


  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');

  // state for create / add modal
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [createKey, setCreateKey] = useState<string>('');
  const [createValue, setCreateValue] = useState<string>('');

  useEffect(() => {
    endpoint?.get(
      'https://api.allmendelabs.com/user/auth0/metadata'
    ).then((response) => {
      // console.log(`userMetadata: ${JSON.stringify(response.data)}`);
      setUserMetadata(response.data);
    }).catch((err) => {
      console.error(`error getting metadata: ${err.message}`);
      console.error(err);
    })
    setLoading(false);
  }, [endpoint]);

  const handleDeleteClick = async (key: string) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this key?');
    if (confirmDelete) {
      await endpoint?.patch(
        'https://api.allmendelabs.com/user/auth0/metadata',
        {data: {
          key: key,
          value: null,
        }}
      );
      setUserMetadata((prev) => {
        const newMetadata = { ...prev };
        delete newMetadata[key];
        return newMetadata;
      });
    }
  };

  // handlers for update modal

  const handleEditClick = (key: string) => {
    setEditableKey(key);
    setEditableValue(userMetadata[key]);
    setUpdateModalOpen(true);
  };

  const handleUpdateModalClose = () => {
    if (hasChanges) {
      const confirmLeave = window.confirm(
        'You have unsaved changes. Do you want to discard them?'
      );
      if (!confirmLeave) return;
    }
    resetUpdateModal();
  };

  const handleSaveUpdateChanges = async () => {
    if (editableKey) {
      try {
        await endpoint?.patch(
          'https://api.allmendelabs.com/user/auth0/metadata',
          { data: {
            key: editableKey,
            value: editableValue,
          } },
        );
        setUserMetadata((prev) => ({ ...prev, [editableKey]: editableValue }));
        setSuccessMessage(
          `${editableKey}: ${editableValue} updated. You may have to log out and back in to see the changes reflected in certain portions of the app.`
        );
        resetUpdateModal();
      } catch (error) {
        // console.error('Error saving user metadata:', error);
        setErrorMessage(`Error saving metadata: ` + error);
        setError(true);
      }
    }
  };

  const resetUpdateModal = () => {
    setEditableKey(null);
    setEditableValue('');
    setHasChanges(false);
    setUpdateModalOpen(false);
  };

  const handleUpdateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableValue(event.target.value);
    setHasChanges(true);
  };

  const handleUpdateOkClick = () => {
    resetUpdateModal();
  };

  // handlers for create / add modal

  const handleCreateClick = () => {
    setCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    if (hasChanges) {
      const confirmLeave = window.confirm(
        'You have unsaved changes. Do you want to discard them?'
      );
      if (!confirmLeave) return;
    }
    resetCreateModal();
  };

  const handleSaveCreateChanges = async () => {
    if (createKey !== '') {
      try {
          await endpoint?.patch(
            'https://api.allmendelabs.com/user/auth0/metadata',
            { data: {
              key: createKey,
              value: createValue,
            } },
        );
        setUserMetadata((prev) => ({ ...prev, [createKey]: createValue }));
        setSuccessMessage(
          `${createKey}: ${createValue} created. You may have to log out and back in to see the changes reflected in certain portions of the app.`
        );
        resetUpdateModal();
      } catch (error) {
        console.error(`Error saving metadata: `, error);
        setError(true)
        setErrorMessage(`Error saving metadata: ` + error);
      }
    }
  };

  const resetCreateModal = () => {
    setCreateKey('');
    setCreateValue('');
    setHasChanges(false);
    setCreateModalOpen(false);
  };

  const handleCreateKeyInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreateKey(event.target.value);
    setHasChanges(true);
  };
  const handleCreateValueInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreateValue(event.target.value);
    setHasChanges(true);
  };

  const handleCreateOkClick = () => {
    resetCreateModal();
  };


  if (loading) {
    return (
      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>
    );
  }

  if (error) {
    return <AlertMessageComponent message={errorMessage} isErrorMessage={true} />;
  }

  return (
    <Box sx={{ margin: '1em', backgroundColor: 'grey', borderRadius: 2 }}>
      <List>
      {Object.entries(userMetadata).map(([key, value]) => (
          <React.Fragment key={key}>
            <ListItem>
              <ListItemText primary={`${key} : ${value}`} sx={{ margin: '1em' }} />
                <Button
                  onClick={() => handleEditClick(key)}
                  sx={{ margin: '1em' }}
                >
                Edit
              </Button>
              <Button
                  onClick={() => handleDeleteClick(key)}
                  sx={{ margin: '1em' }}
                >
                Delete
              </Button>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
        <ListItem>
          <Button
              // onClick={() => handleEditClick(key)}
              onClick={handleCreateClick}
              sx={{ margin: '1em' }}
            >
            Create an entry
          </Button>
        </ListItem>
      </List>

      <Modal open={updateModalOpen} onClose={handleUpdateModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {successMessage ? (
            <>
              <Typography color="black">{successMessage}</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateOkClick}
                sx={{ mt: 2 }}
              >
                Ok
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h6" color="black">{editableKey}</Typography>
              <TextField
                label="Value"
                fullWidth
                value={editableValue}
                onChange={handleUpdateInputChange}
                sx={{ mt: 2 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveUpdateChanges}
                >
                  Save Changes
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={resetUpdateModal}
                >
                  Discard Changes
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>

      <Modal open={createModalOpen} onClose={handleCreateModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {successMessage ? (
            <>
              <Typography color="black">{successMessage}</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateOkClick}
                sx={{ mt: 2 }}
              >
                Ok
              </Button>
            </>
          ) : (
            <>
              <TextField
                label="Key"
                fullWidth
                value={createKey}
                onChange={handleCreateKeyInputChange}
                sx={{ mt: 2 }}
              />
              <TextField
                label="Value"
                fullWidth
                value={createValue}
                onChange={handleCreateValueInputChange}
                sx={{ mt: 2 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveCreateChanges}
                >
                  Save Changes
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={resetCreateModal}
                >
                  Discard Changes
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>

    </Box>
  );
};

export default MetadataEditor;
